@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'regular';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/Raleway/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'thin';
  font-weight: 400;
  src: url("./assets/font/Raleway/Raleway-Light.ttf") format("truetype");
}
@font-face {
  font-family: 'light';
  font-weight: 400;
  src: url("./assets/font/Raleway/Raleway-Light.ttf") format("truetype");
}
@font-face {
  font-family: 'bold';
  font-weight: 400;
  src: url("./assets/font/Raleway/Raleway-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'italic';
  font-weight: 400;
  src: url("./assets/font/Raleway/Raleway-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: 'italic-bold';
  font-weight: 400;
  src: url("./assets/font/Raleway/Raleway-MediumItalic.ttf") format("truetype");
}
  html {
    scroll-behavior: smooth;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  * {
    color: #002143;
  }