.legal {
    padding-top: 10vh;
    * {
        color: white;
    }
    padding-bottom: 100px;
    h1 {
        font-size: 2rem;
        margin-top: 30px;
        font-family: "bold"
    }
    h2 {
        font-size: 1.25rem;
        margin-top: 30px;
        font-family: "bold"
    }
    h3 {
        font-size: 1.10rem;
        margin-top: 30px;
        font-family: "bold"
    }
    p {
        margin-top: 5px;
            margin-bottom: 5px;
        &.margin {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    a {
        color: white;
    }
    ul {
        list-style-type: disc;
        margin-left: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        li {
            font-size: 0.9rem;
        }
    }
    .bold {
        font-weight: bold;
    }
    .large {
        font-size: 1.1rem;
    }
}